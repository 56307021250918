import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
            padding: `${toRem16(15)} ${toRem16(45)}`,
        },
    },
    formActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${toRem16(10)} 0`,
        marginTop: 10,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    TextCenter: {
        textAlign: 'center',
        marginTop: `${toRem16(20)}`,
    },
}))

function HurricaneModal() {
    const classes = useStyles()
    const [openInfoModal, setOpenInfoModal] = useState(true)

    return (
        <UiDialog
            open={openInfoModal}
            handleClose={() => {
                setOpenInfoModal(false)
            }}
            title=""
            size="sm"
            customRootClass={classes.container}
        >
            <Fragment>
                <div>
                    <Fragment>
                        <UiText variant="truck_175" weight="semi_bold_600" className={classes.TextCenter}>
                            Hurricane Milton Alert
                        </UiText>
                        <UiText variant="car_100" className={classes.TextCenter}>
                            Due to Hurricane Milton and its impact on our Florida operations, 
                            we may be slower to handle inquiries, calls and appointments. 
                            Your patience is greatly appreciated as we prioritize the safety 
                            of our employees and their families during this challenging time.
                        </UiText>
                    </Fragment>
                </div>

                <div className={classes.formActions}>
                    <Button
                        variant="contained"
                        onClick={() => setOpenInfoModal(false)}
                        color="primary"
                    >
                        Ok
                    </Button>
                </div>
            </Fragment>
        </UiDialog>
    )
}

export default HurricaneModal
