import React, { useState, useCallback, useEffect } from 'react';
import { ShowReviewResponse } from '../../../models/review.model';
import { showReview } from '../../../services/apiService';
import { getFromLocalStorage, setInLocalStorage } from '../../../services/localStorageService';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {ReviewDialog, REVIEW_SOURCE } from '../../common/ReviewDialog';
import { TOSModal } from '../TOSModal/TOSModal';
import HurricaneModal from './HurricaneModal';
import EstimatedTaxesReminderModal from './EstimatedTaxesReminderModal';
import InfoModal from './InfoModal';
import UncategorizedTransactionsModal from './UncategorizedTransactionsModal';

const REVIEW_DIALOG = getFromLocalStorage('review_dialog')
const DashboardUtilities = ()=> {
    const [showTrustPilot, setShowTrustPilot] = useState(false)
    const { currentUser } = useCurrentStore()
    const isNotAdmin = !currentUser.accountant_mode

   
    const showReviewModal = useCallback(()=>{
        if (
            !(REVIEW_DIALOG?.showReview && isNotAdmin)
        ) {
            setShowTrustPilot(false)
            return
        }
        showReview(REVIEW_SOURCE).then((response: ShowReviewResponse) => {
            if (response.showreview) {
                setInLocalStorage('review_dialog', {
                    showReview: false,
                })
                setShowTrustPilot(true)
            }
        })
    },[])

    useEffect(() => {
        showReviewModal()
    }, [showReviewModal])


    return (
        <div>
            <HurricaneModal/>
            <TOSModal/>
            <InfoModal />
            <EstimatedTaxesReminderModal />
            <UncategorizedTransactionsModal />
            {showTrustPilot && <ReviewDialog />}
        </div>
    )
}

export default DashboardUtilities